import Business from './Business'
import User from './User'
import PaymentDetail from './PaymentDetail'
export default class Payment {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //BusinessID is required
      BusinessID: 0,
      CheckNumber: null,
      ProcessDate: null,
      //PaymentAmount is required
      PaymentAmount: 0,
      BatchNumber: null,
      //Void is required
      Void: false,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.BusinessID = obj.BusinessID
    this.CheckNumber = obj.CheckNumber
    this.ProcessDate = obj.ProcessDate
    this.PaymentAmount = obj.PaymentAmount
    this.BatchNumber = obj.BatchNumber
    this.Void = obj.Void
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.Business = new Business(obj.Business || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.PaymentDetail = []
    if (obj.PaymentDetail) {
      obj.PaymentDetail.forEach((item) => {
        this.PaymentDetail.push(new PaymentDetail(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.PaymentDetail = []
    if (this.PaymentDetail) {
      this.PaymentDetail.forEach((item) => {
        obj.PaymentDetail.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.PaymentDetail = null
    return obj
  }
}
