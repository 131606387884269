import LuRole from './LuRole'
import User from './User'
export default class UserRole {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserID is required
      UserID: 0,
      //RoleID is required
      RoleID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserID = obj.UserID
    this.RoleID = obj.RoleID
    this.Role = new LuRole(obj.Role || {})
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Role = null
    obj.User = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Role = null
    obj.User = null
    return obj
  }
}
