import Business from './Business'
export default class LuState {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Abr is required
      Abr: '',
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Abr = obj.Abr
    this.Description = obj.Description
    this.BusinessBillingState = []
    if (obj.BusinessBillingState) {
      obj.BusinessBillingState.forEach((item) => {
        this.BusinessBillingState.push(new Business(item))
      })
    }
    this.BusinessMailingState = []
    if (obj.BusinessMailingState) {
      obj.BusinessMailingState.forEach((item) => {
        this.BusinessMailingState.push(new Business(item))
      })
    }
    this.BusinessPhysicalState = []
    if (obj.BusinessPhysicalState) {
      obj.BusinessPhysicalState.forEach((item) => {
        this.BusinessPhysicalState.push(new Business(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.BusinessBillingState = []
    if (this.BusinessBillingState) {
      this.BusinessBillingState.forEach((item) => {
        obj.BusinessBillingState.push(item.removeRelated())
      })
    }
    obj.BusinessMailingState = []
    if (this.BusinessMailingState) {
      this.BusinessMailingState.forEach((item) => {
        obj.BusinessMailingState.push(item.removeRelated())
      })
    }
    obj.BusinessPhysicalState = []
    if (this.BusinessPhysicalState) {
      this.BusinessPhysicalState.forEach((item) => {
        obj.BusinessPhysicalState.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.BusinessBillingState = null
    obj.BusinessMailingState = null
    obj.BusinessPhysicalState = null
    return obj
  }
}
