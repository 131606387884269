import User from './User'
import Document from './Document'
import LicenseDocumentTypes from './LicenseDocumentTypes'
export default class LuDocumentType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
      IsActive: true,
      InternalOnly: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.IsActive = obj.IsActive
    this.InternalOnly = obj.InternalOnly
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.Document = []
    if (obj.Document) {
      obj.Document.forEach((item) => {
        this.Document.push(new Document(item))
      })
    }
    this.LicenseDocumentTypes = []
    if (obj.LicenseDocumentTypes) {
      obj.LicenseDocumentTypes.forEach((item) => {
        this.LicenseDocumentTypes.push(new LicenseDocumentTypes(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.Document = []
    if (this.Document) {
      this.Document.forEach((item) => {
        obj.Document.push(item.removeRelated())
      })
    }
    obj.LicenseDocumentTypes = []
    if (this.LicenseDocumentTypes) {
      this.LicenseDocumentTypes.forEach((item) => {
        obj.LicenseDocumentTypes.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.Document = null
    obj.LicenseDocumentTypes = null
    return obj
  }
}
