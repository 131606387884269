import UserBusiness from './UserBusiness'
export default class LuBusinessRole {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.UserBusiness = []
    if (obj.UserBusiness) {
      obj.UserBusiness.forEach((item) => {
        this.UserBusiness.push(new UserBusiness(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.UserBusiness = []
    if (this.UserBusiness) {
      this.UserBusiness.forEach((item) => {
        obj.UserBusiness.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.UserBusiness = null
    return obj
  }
}
