import Business from './Business'
import LuLicenseType from './LuLicenseType'
import User from './User'
import LicenseNotes from './LicenseNotes'
import LicensePeriod from './LicensePeriod'
import LicenseSubType from './LicenseSubType'
export default class License {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //BusinessID is required
      BusinessID: 0,
      //LicenseTypeID is required
      LicenseTypeID: 0,
      IsActive: false,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.BusinessID = obj.BusinessID
    this.LicenseTypeID = obj.LicenseTypeID
    this.IsActive = obj.IsActive
    this.LicenseNumber = obj.LicenseNumber
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.Business = new Business(obj.Business || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.LicenseType = new LuLicenseType(obj.LicenseType || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.HempLicense = obj.HempLicense || {}
    this.LicenseNotes = []
    if (obj.LicenseNotes) {
      obj.LicenseNotes.forEach((item) => {
        this.LicenseNotes.push(new LicenseNotes(item))
      })
    }
    this.LicensePeriod = []
    if (obj.LicensePeriod) {
      obj.LicensePeriod.forEach((item) => {
        this.LicensePeriod.push(new LicensePeriod(item))
      })
    }
    this.LicenseSubType = []
    if (obj.LicenseSubType) {
      obj.LicenseSubType.forEach((item) => {
        this.LicenseSubType.push(new LicenseSubType(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.InsertBy = null
    obj.LicenseType = null
    obj.UpdateBy = null
    obj.HempLicense = null
    obj.LicenseNotes = []
    if (this.LicenseNotes) {
      this.LicenseNotes.forEach((item) => {
        obj.LicenseNotes.push(item.removeRelated())
      })
    }
    obj.LicensePeriod = []
    if (this.LicensePeriod) {
      this.LicensePeriod.forEach((item) => {
        obj.LicensePeriod.push(item.removeRelated())
      })
    }
    obj.LicenseSubType = []
    if (this.LicenseSubType) {
      this.LicenseSubType.forEach((item) => {
        obj.LicenseSubType.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.InsertBy = null
    obj.LicenseType = null
    obj.UpdateBy = null
    obj.HempLicense = null
    obj.LicenseNotes = null
    obj.LicensePeriod = null
    obj.LicenseSubType = null
    return obj
  }
}
