import User from './User'
import License from './License'
export default class LicenseNotes {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //LicenseID is required
      LicenseID: 0,
      Note: null,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseID = obj.LicenseID
    this.Note = obj.Note
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.License = new License(obj.License || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    return obj
  }
}
