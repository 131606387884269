import User from './User'
import License from './License'
import LicenseStatus from './LicenseStatus'
import PaymentDetail from './PaymentDetail'
export default class LicensePeriod {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //LicenseID is required
      LicenseID: 0,
      //LicenseFee is required
      LicenseFee: 0,
      //ExpirationDate is required
      ExpirationDate: new Date().toISOString().split('T')[0],
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseID = obj.LicenseID
    this.LicenseFee = obj.LicenseFee
    this.ExpirationDate = obj.ExpirationDate
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.License = new License(obj.License || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.LicenseStatus = []
    if (obj.LicenseStatus) {
      obj.LicenseStatus.forEach((item) => {
        this.LicenseStatus.push(new LicenseStatus(item))
      })
    }
    this.PaymentDetail = []
    if (obj.PaymentDetail) {
      obj.PaymentDetail.forEach((item) => {
        this.PaymentDetail.push(new PaymentDetail(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    obj.LicenseStatus = []
    if (this.LicenseStatus) {
      this.LicenseStatus.forEach((item) => {
        obj.LicenseStatus.push(item.removeRelated())
      })
    }
    obj.PaymentDetail = []
    if (this.PaymentDetail) {
      this.PaymentDetail.forEach((item) => {
        obj.PaymentDetail.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    obj.LicenseStatus = null
    obj.PaymentDetail = null
    return obj
  }
}
