import User from './User'
import LicenseStatus from './LicenseStatus'
export default class LuLicenseStatus {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Description: null,
      //IsActive is required
      IsActive: true,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.IsActive = obj.IsActive
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.LicenseStatus = []
    if (obj.LicenseStatus) {
      obj.LicenseStatus.forEach((item) => {
        this.LicenseStatus.push(new LicenseStatus(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.LicenseStatus = []
    if (this.LicenseStatus) {
      this.LicenseStatus.forEach((item) => {
        obj.LicenseStatus.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.LicenseStatus = null
    return obj
  }
}