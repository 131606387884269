import License from './License'
import LuLicenseSubType from './LuLicenseSubType'
export default class LicenseSubType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //LicenseID is required
      LicenseID: 0,
      //LicenseSubTypeID is required
      LicenseSubTypeID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseID = obj.LicenseID
    this.LicenseSubTypeID = obj.LicenseSubTypeID
    this.License = new License(obj.License || {})
    this.LicenseSubTypeNavigation = new LuLicenseSubType(obj.LicenseSubTypeNavigation || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.License = null
    obj.LicenseSubTypeNavigation = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.License = null
    obj.LicenseSubTypeNavigation = null
    return obj
  }
}
