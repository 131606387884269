import User from './User'
import LuLicenseType from './LuLicenseType'
import LicenseDocumentTypes from './LicenseDocumentTypes'
import LicenseSubType from './LicenseSubType'
export default class LuLicenseSubType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //LicenseTypeID is required
      LicenseTypeID: 0,
      //LicenseCode is required
      LicenseCode: '',
      //RevenueCode is required
      RevenueCode: 0,
      //Description is required
      Description: '',
      //LicenseFee is required
      LicenseFee: 0,
      //TermInYears is required
      TermInYears: 0,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseTypeID = obj.LicenseTypeID
    this.LicenseCode = obj.LicenseCode
    this.RevenueCode = obj.RevenueCode
    this.Description = obj.Description
    this.LicenseFee = obj.LicenseFee
    this.TermInYears = obj.TermInYears
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.LicenseType = new LuLicenseType(obj.LicenseType || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.LicenseDocumentTypes = []
    if (obj.LicenseDocumentTypes) {
      obj.LicenseDocumentTypes.forEach((item) => {
        this.LicenseDocumentTypes.push(new LicenseDocumentTypes(item))
      })
    }
    this.LicenseSubType = []
    if (obj.LicenseSubType) {
      obj.LicenseSubType.forEach((item) => {
        this.LicenseSubType.push(new LicenseSubType(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.LicenseType = null
    obj.UpdateBy = null
    obj.LicenseDocumentTypes = []
    if (this.LicenseDocumentTypes) {
      this.LicenseDocumentTypes.forEach((item) => {
        obj.LicenseDocumentTypes.push(item.removeRelated())
      })
    }
    obj.LicenseSubType = []
    if (this.LicenseSubType) {
      this.LicenseSubType.forEach((item) => {
        obj.LicenseSubType.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.LicenseType = null
    obj.UpdateBy = null
    obj.LicenseDocumentTypes = null
    obj.LicenseSubType = null
    return obj
  }
}
