import Business from './Business'
import LuBusinessRole from './LuBusinessRole'
import User from './User'
export default class UserBusiness {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserID is required
      UserID: 0,
      //BusinessID is required
      BusinessID: 0,
      BusinessRoleID: null,
      //IsActive is required
      IsActive: true,
      IsConfirmed: false,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserID = obj.UserID
    this.BusinessID = obj.BusinessID
    this.BusinessRoleID = obj.BusinessRoleID
    this.IsActive = obj.IsActive
    this.IsConfirmed = obj.IsConfirmed
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.Business = new Business(obj.Business || {})
    this.BusinessRole = new LuBusinessRole(obj.BusinessRole || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.BusinessRole = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.User = null
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.BusinessRole = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.User = this.User.removeRelated()
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Business = null
    obj.BusinessRole = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.User = null
    return obj
  }
}