import LuDocumentType from './LuDocumentType'
import User from './User'
// import HempLicenseDocument from './HempLicenseDocument'
export default class Document {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //FileName is required
      FileName: '',
      //MimeType is required
      MimeType: '',
      //DocumentTypeID is required
      DocumentTypeID: 0,
      //Image is required
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      DocumentFile: { Image: null },
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.FileName = obj.FileName
    this.MimeType = obj.MimeType
    this.DocumentTypeID = obj.DocumentTypeID
    this.Image = obj.Image
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.DocumentFile = obj.DocumentFile
    this.DocumentType = new LuDocumentType(obj.DocumentType || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.HempLicenseDocument = obj.HempLicenseDocument || {}
    

  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    obj.InsertBy = null
    obj.DocumentFile = null
    this.HempLicenseDocument = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    obj.InsertBy = null
    obj.HempLicenseDocument = null
    obj.DocumentFile = null
    return obj
  }
}
