import User from './User'
import License from './License'
import LicenseDocumentTypes from './LicenseDocumentTypes'
import LuLicenseSubType from './LuLicenseSubType'
export default class LuLicenseType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Name: null,
      //Description is required
      Description: '',
      //ExpireDateEachYear is required
      ExpireDateEachYear: '',
      MultipleSubTypesAvailable: null,
      SingleFee: null,
      LicenseTemplateID: 0,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Name = obj.Name
    this.Description = obj.Description
    this.ExpireDateEachYear = obj.ExpireDateEachYear
    this.MultipleSubTypesAvailable = obj.MultipleSubTypesAvailable
    this.SingleFee = obj.SingleFee
    this.LicenseTemplateID = obj.LicenseTemplateID
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.License = []
    if (obj.License) {
      obj.License.forEach((item) => {
        this.License.push(new License(item))
      })
    }
    this.LicenseDocumentTypes = []
    if (obj.LicenseDocumentTypes) {
      obj.LicenseDocumentTypes.forEach((item) => {
        this.LicenseDocumentTypes.push(new LicenseDocumentTypes(item))
      })
    }
    this.LuLicenseSubType = []
    if (obj.LuLicenseSubType) {
      obj.LuLicenseSubType.forEach((item) => {
        this.LuLicenseSubType.push(new LuLicenseSubType(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.License = []
    if (this.License) {
      this.License.forEach((item) => {
        obj.License.push(item.removeRelated())
      })
    }
    obj.LicenseDocumentTypes = []
    if (this.LicenseDocumentTypes) {
      this.LicenseDocumentTypes.forEach((item) => {
        obj.LicenseDocumentTypes.push(item.removeRelated())
      })
    }
    obj.LuLicenseSubType = []
    if (this.LuLicenseSubType) {
      this.LuLicenseSubType.forEach((item) => {
        obj.LuLicenseSubType.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.License = null
    obj.LicenseDocumentTypes = null
    obj.LuLicenseSubType = null
    return obj
  }
}
