import User from './User'
import LicensePeriod from './LicensePeriod'
import Payment from './Payment'
export default class PaymentDetail {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //PaymentID is required
      PaymentID: 0,
      //LicensePeriodID is required
      LicensePeriodID: 0,
      //Amount is required
      Amount: 0,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.PaymentID = obj.PaymentID
    this.LicensePeriodID = obj.LicensePeriodID
    this.Amount = obj.Amount
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.LicensePeriod = new LicensePeriod(obj.LicensePeriod || {})
    this.Payment = new Payment(obj.Payment || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.LicensePeriod = null
    obj.Payment = null
    obj.UpdateBy = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.LicensePeriod = null
    obj.Payment = null
    obj.UpdateBy = null
    return obj
  }
}
