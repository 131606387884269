
import LuState from './LuState'
import User from './User'
import License from './License'
import Payment from './Payment'
import UserBusiness from './UserBusiness'
export default class Business {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      PreviousBusinessID: null,
      //BusinessName is required
      BusinessName: '',
      Phone: null,
      PhoneExt: null,
      AltPhone: null,
      AltPhoneExt: null,
      Email: null,
      AltEmail: null,
      PhysicalAddress: null,
      PhysicalCity: null,
      PhysicalStateID: null,
      PhysicalZip: null,
      BillingAddress: null,
      BillingCity: null,
      BillingStateID: null,
      BillingZip: null,
      MailingAddress: null,
      MailingCity: null,
      MailingStateID: null,
      MailingZip: null,
      //IsActive is required
      IsActive: true,
      InactiveDate: null,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.PreviousBusinessID = obj.PreviousBusinessID
    this.BusinessName = obj.BusinessName
    this.Phone = obj.Phone
    this.PhoneExt = obj.PhoneExt
    this.AltPhone = obj.AltPhone
    this.AltPhoneExt = obj.AltPhoneExt
    this.Email = obj.Email
    this.AltEmail = obj.AltEmail
    this.PhysicalAddress = obj.PhysicalAddress
    this.PhysicalCity = obj.PhysicalCity
    this.PhysicalStateID = obj.PhysicalStateID
    this.PhysicalZip = obj.PhysicalZip
    this.BillingAddress = obj.BillingAddress
    this.BillingCity = obj.BillingCity
    this.BillingStateID = obj.BillingStateID
    this.BillingZip = obj.BillingZip
    this.MailingAddress = obj.MailingAddress
    this.MailingCity = obj.MailingCity
    this.MailingStateID = obj.MailingStateID
    this.MailingZip = obj.MailingZip
    this.IsActive = obj.IsActive
    this.InactiveDate = obj.InactiveDate
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.BillingState = new LuState(obj.BillingState || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.MailingState = new LuState(obj.MailingState || {})
    this.PhysicalState = new LuState(obj.PhysicalState || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.License = []
    if (obj.License) {
      obj.License.forEach((item) => {
        this.License.push(new License(item))
      })
    }
    this.Payment = []
    if (obj.Payment) {
      obj.Payment.forEach((item) => {
        this.Payment.push(new Payment(item))
      })
    }
    this.UserBusiness = []
    if (obj.UserBusiness) {
      obj.UserBusiness.forEach((item) => {
        this.UserBusiness.push(new UserBusiness(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.BillingState = null
    obj.InsertBy = null
    obj.MailingState = null
    obj.PhysicalState = null
    obj.UpdateBy = null
    obj.License = []
    if (this.License) {
      this.License.forEach((item) => {
        obj.License.push(item.removeRelated())
      })
    }
    obj.Payment = []
    if (this.Payment) {
      this.Payment.forEach((item) => {
        obj.Payment.push(item.removeRelated())
      })
    }
    obj.UserBusiness = []
    if (this.UserBusiness) {
      this.UserBusiness.forEach((item) => {
        obj.UserBusiness.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.UserBusiness = []
    if (this.UserBusiness) {
      this.UserBusiness.forEach((item) => {
        obj.UserBusiness.push(item.root())
      })
      obj.BillingState = null
      obj.InsertBy = null
      obj.MailingState = null
      obj.PhysicalState = null
      obj.UpdateBy = null
      obj.License = null
      obj.Payment = null
      //obj.UserBusiness = null
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.BillingState = null
    obj.InsertBy = null
    obj.MailingState = null
    obj.PhysicalState = null
    obj.UpdateBy = null
    obj.License = null
    obj.Payment = null
    obj.UserBusiness = null
    return obj
  }
}
