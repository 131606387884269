import LuDocumentType from './LuDocumentType'
import User from './User'
import LuLicenseSubType from './LuLicenseSubType'
import LuLicenseType from './LuLicenseType'
export default class LicenseDocumentTypes {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      LicenseTypeID: null,
      LicenseSubTypeID: null,
      //DocumentTypeID is required
      DocumentTypeID: 0,
      //IsRequired is required
      IsRequired: false,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseTypeID = obj.LicenseTypeID
    this.LicenseSubTypeID = obj.LicenseSubTypeID
    this.DocumentTypeID = obj.DocumentTypeID
    this.IsRequired = obj.IsRequired
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.DocumentType = new LuDocumentType(obj.DocumentType || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.LicenseSubType = new LuLicenseSubType(obj.LicenseSubType || {})
    this.LicenseType = new LuLicenseType(obj.LicenseType || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    obj.InsertBy = null
    obj.LicenseSubType = null
    obj.LicenseType = null
    obj.UpdateBy = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    obj.InsertBy = null
    obj.LicenseSubType = null
    obj.LicenseType = null
    obj.UpdateBy = null
    return obj
  }
}
