// import Business from './Business'
// import Document from './Document'
// import HempLicenseArea from './HempLicenseArea'
// import HempLicense from './HempLicense'
// import LicenseDocumentTypes from './LicenseDocumentTypes'
// import License from './License'
// import LicenseNotes from './LicenseNotes'
// import LicensePeriod from './LicensePeriod'
// import LicenseStatus from './LicenseStatus'
// import LuBusinessType from './LuBusinessType'
// import LuDocumentType from './LuDocumentType'
// import LuHempOwnership from './LuHempOwnership'
// import LuLicenseStatus from './LuLicenseStatus'
// import LuLicenseSubType from './LuLicenseSubType'
// import LuLicenseType from './LuLicenseType'
// import PaymentDetail from './PaymentDetail'
// import Payment from './Payment'
import UserBusiness from './UserBusiness'
import UserRole from './UserRole'
export default class User {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Email is required
      Email: '',
      //FirstName is required
      FirstName: '',
      //LastName is required
      LastName: '',
      //IsActive is required
      IsActive: true,
      //DeletedByUser is required
      DeletedByUser: false,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Email = obj.Email
    this.FirstName = obj.FirstName
    this.LastName = obj.LastName
    this.IsActive = obj.IsActive
    this.DeletedByUser = obj.DeletedByUser
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    // this.BusinessInsertBy = []
    // if (obj.BusinessInsertBy) {
    //   obj.BusinessInsertBy.forEach((item) => {
    //     this.BusinessInsertBy.push(new Business(item))
    //   })
    // }
    // this.BusinessUpdateBy = []
    // if (obj.BusinessUpdateBy) {
    //   obj.BusinessUpdateBy.forEach((item) => {
    //     this.BusinessUpdateBy.push(new Business(item))
    //   })
    // }
    // this.Document = []
    // if (obj.Document) {
    //   obj.Document.forEach((item) => {
    //     this.Document.push(new Document(item))
    //   })
    // }
    // this.HempLicenseAreaInsertBy = []
    // if (obj.HempLicenseAreaInsertBy) {
    //   obj.HempLicenseAreaInsertBy.forEach((item) => {
    //     this.HempLicenseAreaInsertBy.push(new HempLicenseArea(item))
    //   })
    // }
    // this.HempLicenseAreaUpdateBy = []
    // if (obj.HempLicenseAreaUpdateBy) {
    //   obj.HempLicenseAreaUpdateBy.forEach((item) => {
    //     this.HempLicenseAreaUpdateBy.push(new HempLicenseArea(item))
    //   })
    // }
    // this.HempLicenseInsertBy = []
    // if (obj.HempLicenseInsertBy) {
    //   obj.HempLicenseInsertBy.forEach((item) => {
    //     this.HempLicenseInsertBy.push(new HempLicense(item))
    //   })
    // }
    // this.HempLicenseUpdateBy = []
    // if (obj.HempLicenseUpdateBy) {
    //   obj.HempLicenseUpdateBy.forEach((item) => {
    //     this.HempLicenseUpdateBy.push(new HempLicense(item))
    //   })
    // }
    // this.LicenseDocumentTypesInsertBy = []
    // if (obj.LicenseDocumentTypesInsertBy) {
    //   obj.LicenseDocumentTypesInsertBy.forEach((item) => {
    //     this.LicenseDocumentTypesInsertBy.push(new LicenseDocumentTypes(item))
    //   })
    // }
    // this.LicenseDocumentTypesUpdateBy = []
    // if (obj.LicenseDocumentTypesUpdateBy) {
    //   obj.LicenseDocumentTypesUpdateBy.forEach((item) => {
    //     this.LicenseDocumentTypesUpdateBy.push(new LicenseDocumentTypes(item))
    //   })
    // }
    // this.LicenseInsertBy = []
    // if (obj.LicenseInsertBy) {
    //   obj.LicenseInsertBy.forEach((item) => {
    //     this.LicenseInsertBy.push(new License(item))
    //   })
    // }
    // this.LicenseNotesInsertBy = []
    // if (obj.LicenseNotesInsertBy) {
    //   obj.LicenseNotesInsertBy.forEach((item) => {
    //     this.LicenseNotesInsertBy.push(new LicenseNotes(item))
    //   })
    // }
    // this.LicenseNotesUpdateBy = []
    // if (obj.LicenseNotesUpdateBy) {
    //   obj.LicenseNotesUpdateBy.forEach((item) => {
    //     this.LicenseNotesUpdateBy.push(new LicenseNotes(item))
    //   })
    // }
    // this.LicensePeriodInsertBy = []
    // if (obj.LicensePeriodInsertBy) {
    //   obj.LicensePeriodInsertBy.forEach((item) => {
    //     this.LicensePeriodInsertBy.push(new LicensePeriod(item))
    //   })
    // }
    // this.LicensePeriodUpdateBy = []
    // if (obj.LicensePeriodUpdateBy) {
    //   obj.LicensePeriodUpdateBy.forEach((item) => {
    //     this.LicensePeriodUpdateBy.push(new LicensePeriod(item))
    //   })
    // }
    // this.LicenseStatus = []
    // if (obj.LicenseStatus) {
    //   obj.LicenseStatus.forEach((item) => {
    //     this.LicenseStatus.push(new LicenseStatus(item))
    //   })
    // }
    // this.LicenseUpdateBy = []
    // if (obj.LicenseUpdateBy) {
    //   obj.LicenseUpdateBy.forEach((item) => {
    //     this.LicenseUpdateBy.push(new License(item))
    //   })
    // }
    // this.LuBusinessTypeInsertBy = []
    // if (obj.LuBusinessTypeInsertBy) {
    //   obj.LuBusinessTypeInsertBy.forEach((item) => {
    //     this.LuBusinessTypeInsertBy.push(new LuBusinessType(item))
    //   })
    // }
    // this.LuBusinessTypeUpdateBy = []
    // if (obj.LuBusinessTypeUpdateBy) {
    //   obj.LuBusinessTypeUpdateBy.forEach((item) => {
    //     this.LuBusinessTypeUpdateBy.push(new LuBusinessType(item))
    //   })
    // }
    // this.LuDocumentTypeInsertBy = []
    // if (obj.LuDocumentTypeInsertBy) {
    //   obj.LuDocumentTypeInsertBy.forEach((item) => {
    //     this.LuDocumentTypeInsertBy.push(new LuDocumentType(item))
    //   })
    // }
    // this.LuDocumentTypeUpdateBy = []
    // if (obj.LuDocumentTypeUpdateBy) {
    //   obj.LuDocumentTypeUpdateBy.forEach((item) => {
    //     this.LuDocumentTypeUpdateBy.push(new LuDocumentType(item))
    //   })
    // }
    // this.LuHempOwnershipInsertBy = []
    // if (obj.LuHempOwnershipInsertBy) {
    //   obj.LuHempOwnershipInsertBy.forEach((item) => {
    //     this.LuHempOwnershipInsertBy.push(new LuHempOwnership(item))
    //   })
    // }
    // this.LuHempOwnershipUpdateBy = []
    // if (obj.LuHempOwnershipUpdateBy) {
    //   obj.LuHempOwnershipUpdateBy.forEach((item) => {
    //     this.LuHempOwnershipUpdateBy.push(new LuHempOwnership(item))
    //   })
    // }
    // this.LuLicenseStatusInsertBy = []
    // if (obj.LuLicenseStatusInsertBy) {
    //   obj.LuLicenseStatusInsertBy.forEach((item) => {
    //     this.LuLicenseStatusInsertBy.push(new LuLicenseStatus(item))
    //   })
    // }
    // this.LuLicenseStatusUpdateBy = []
    // if (obj.LuLicenseStatusUpdateBy) {
    //   obj.LuLicenseStatusUpdateBy.forEach((item) => {
    //     this.LuLicenseStatusUpdateBy.push(new LuLicenseStatus(item))
    //   })
    // }
    // this.LuLicenseSubTypeInsertBy = []
    // if (obj.LuLicenseSubTypeInsertBy) {
    //   obj.LuLicenseSubTypeInsertBy.forEach((item) => {
    //     this.LuLicenseSubTypeInsertBy.push(new LuLicenseSubType(item))
    //   })
    // }
    // this.LuLicenseSubTypeUpdateBy = []
    // if (obj.LuLicenseSubTypeUpdateBy) {
    //   obj.LuLicenseSubTypeUpdateBy.forEach((item) => {
    //     this.LuLicenseSubTypeUpdateBy.push(new LuLicenseSubType(item))
    //   })
    // }
    // this.LuLicenseTypeInsertBy = []
    // if (obj.LuLicenseTypeInsertBy) {
    //   obj.LuLicenseTypeInsertBy.forEach((item) => {
    //     this.LuLicenseTypeInsertBy.push(new LuLicenseType(item))
    //   })
    // }
    // this.LuLicenseTypeUpdateBy = []
    // if (obj.LuLicenseTypeUpdateBy) {
    //   obj.LuLicenseTypeUpdateBy.forEach((item) => {
    //     this.LuLicenseTypeUpdateBy.push(new LuLicenseType(item))
    //   })
    // }
    // this.PaymentDetailInsertBy = []
    // if (obj.PaymentDetailInsertBy) {
    //   obj.PaymentDetailInsertBy.forEach((item) => {
    //     this.PaymentDetailInsertBy.push(new PaymentDetail(item))
    //   })
    // }
    // this.PaymentDetailUpdateBy = []
    // if (obj.PaymentDetailUpdateBy) {
    //   obj.PaymentDetailUpdateBy.forEach((item) => {
    //     this.PaymentDetailUpdateBy.push(new PaymentDetail(item))
    //   })
    // }
    // this.PaymentInsertBy = []
    // if (obj.PaymentInsertBy) {
    //   obj.PaymentInsertBy.forEach((item) => {
    //     this.PaymentInsertBy.push(new Payment(item))
    //   })
    // }
    // this.PaymentUpdateBy = []
    // if (obj.PaymentUpdateBy) {
    //   obj.PaymentUpdateBy.forEach((item) => {
    //     this.PaymentUpdateBy.push(new Payment(item))
    //   })
    // }
    // this.UserBusinessInsertBy = []
    // if (obj.UserBusinessInsertBy) {
    //   obj.UserBusinessInsertBy.forEach((item) => {
    //     this.UserBusinessInsertBy.push(new UserBusiness(item))
    //   })
    // }
    // this.UserBusinessUpdateBy = []
    // if (obj.UserBusinessUpdateBy) {
    //   obj.UserBusinessUpdateBy.forEach((item) => {
    //     this.UserBusinessUpdateBy.push(new UserBusiness(item))
    //   })
    // }
    this.UserBusinessUser = []
    if (obj.UserBusinessUser) {
      obj.UserBusinessUser.forEach((item) => {
        this.UserBusinessUser.push(new UserBusiness(item))
      })
    }
    this.UserRole = []
    if (obj.UserRole) {
      obj.UserRole.forEach((item) => {
        this.UserRole.push(new UserRole(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    // obj.BusinessInsertBy = []
    // if (this.BusinessInsertBy) {
    //   this.BusinessInsertBy.forEach((item) => {
    //     obj.BusinessInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.BusinessUpdateBy = []
    // if (this.BusinessUpdateBy) {
    //   this.BusinessUpdateBy.forEach((item) => {
    //     obj.BusinessUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.Document = []
    // if (this.Document) {
    //   this.Document.forEach((item) => {
    //     obj.Document.push(item.removeRelated())
    //   })
    // }
    // obj.HempLicenseAreaInsertBy = []
    // if (this.HempLicenseAreaInsertBy) {
    //   this.HempLicenseAreaInsertBy.forEach((item) => {
    //     obj.HempLicenseAreaInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.HempLicenseAreaUpdateBy = []
    // if (this.HempLicenseAreaUpdateBy) {
    //   this.HempLicenseAreaUpdateBy.forEach((item) => {
    //     obj.HempLicenseAreaUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.HempLicenseInsertBy = []
    // if (this.HempLicenseInsertBy) {
    //   this.HempLicenseInsertBy.forEach((item) => {
    //     obj.HempLicenseInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.HempLicenseUpdateBy = []
    // if (this.HempLicenseUpdateBy) {
    //   this.HempLicenseUpdateBy.forEach((item) => {
    //     obj.HempLicenseUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseDocumentTypesInsertBy = []
    // if (this.LicenseDocumentTypesInsertBy) {
    //   this.LicenseDocumentTypesInsertBy.forEach((item) => {
    //     obj.LicenseDocumentTypesInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseDocumentTypesUpdateBy = []
    // if (this.LicenseDocumentTypesUpdateBy) {
    //   this.LicenseDocumentTypesUpdateBy.forEach((item) => {
    //     obj.LicenseDocumentTypesUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseInsertBy = []
    // if (this.LicenseInsertBy) {
    //   this.LicenseInsertBy.forEach((item) => {
    //     obj.LicenseInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseNotesInsertBy = []
    // if (this.LicenseNotesInsertBy) {
    //   this.LicenseNotesInsertBy.forEach((item) => {
    //     obj.LicenseNotesInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseNotesUpdateBy = []
    // if (this.LicenseNotesUpdateBy) {
    //   this.LicenseNotesUpdateBy.forEach((item) => {
    //     obj.LicenseNotesUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicensePeriodInsertBy = []
    // if (this.LicensePeriodInsertBy) {
    //   this.LicensePeriodInsertBy.forEach((item) => {
    //     obj.LicensePeriodInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicensePeriodUpdateBy = []
    // if (this.LicensePeriodUpdateBy) {
    //   this.LicensePeriodUpdateBy.forEach((item) => {
    //     obj.LicensePeriodUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseStatus = []
    // if (this.LicenseStatus) {
    //   this.LicenseStatus.forEach((item) => {
    //     obj.LicenseStatus.push(item.removeRelated())
    //   })
    // }
    // obj.LicenseUpdateBy = []
    // if (this.LicenseUpdateBy) {
    //   this.LicenseUpdateBy.forEach((item) => {
    //     obj.LicenseUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuBusinessTypeInsertBy = []
    // if (this.LuBusinessTypeInsertBy) {
    //   this.LuBusinessTypeInsertBy.forEach((item) => {
    //     obj.LuBusinessTypeInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuBusinessTypeUpdateBy = []
    // if (this.LuBusinessTypeUpdateBy) {
    //   this.LuBusinessTypeUpdateBy.forEach((item) => {
    //     obj.LuBusinessTypeUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuDocumentTypeInsertBy = []
    // if (this.LuDocumentTypeInsertBy) {
    //   this.LuDocumentTypeInsertBy.forEach((item) => {
    //     obj.LuDocumentTypeInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuDocumentTypeUpdateBy = []
    // if (this.LuDocumentTypeUpdateBy) {
    //   this.LuDocumentTypeUpdateBy.forEach((item) => {
    //     obj.LuDocumentTypeUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuHempOwnershipInsertBy = []
    // if (this.LuHempOwnershipInsertBy) {
    //   this.LuHempOwnershipInsertBy.forEach((item) => {
    //     obj.LuHempOwnershipInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuHempOwnershipUpdateBy = []
    // if (this.LuHempOwnershipUpdateBy) {
    //   this.LuHempOwnershipUpdateBy.forEach((item) => {
    //     obj.LuHempOwnershipUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseStatusInsertBy = []
    // if (this.LuLicenseStatusInsertBy) {
    //   this.LuLicenseStatusInsertBy.forEach((item) => {
    //     obj.LuLicenseStatusInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseStatusUpdateBy = []
    // if (this.LuLicenseStatusUpdateBy) {
    //   this.LuLicenseStatusUpdateBy.forEach((item) => {
    //     obj.LuLicenseStatusUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseSubTypeInsertBy = []
    // if (this.LuLicenseSubTypeInsertBy) {
    //   this.LuLicenseSubTypeInsertBy.forEach((item) => {
    //     obj.LuLicenseSubTypeInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseSubTypeUpdateBy = []
    // if (this.LuLicenseSubTypeUpdateBy) {
    //   this.LuLicenseSubTypeUpdateBy.forEach((item) => {
    //     obj.LuLicenseSubTypeUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseTypeInsertBy = []
    // if (this.LuLicenseTypeInsertBy) {
    //   this.LuLicenseTypeInsertBy.forEach((item) => {
    //     obj.LuLicenseTypeInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.LuLicenseTypeUpdateBy = []
    // if (this.LuLicenseTypeUpdateBy) {
    //   this.LuLicenseTypeUpdateBy.forEach((item) => {
    //     obj.LuLicenseTypeUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.PaymentDetailInsertBy = []
    // if (this.PaymentDetailInsertBy) {
    //   this.PaymentDetailInsertBy.forEach((item) => {
    //     obj.PaymentDetailInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.PaymentDetailUpdateBy = []
    // if (this.PaymentDetailUpdateBy) {
    //   this.PaymentDetailUpdateBy.forEach((item) => {
    //     obj.PaymentDetailUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.PaymentInsertBy = []
    // if (this.PaymentInsertBy) {
    //   this.PaymentInsertBy.forEach((item) => {
    //     obj.PaymentInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.PaymentUpdateBy = []
    // if (this.PaymentUpdateBy) {
    //   this.PaymentUpdateBy.forEach((item) => {
    //     obj.PaymentUpdateBy.push(item.removeRelated())
    //   })
    // }
    // obj.UserBusinessInsertBy = []
    // if (this.UserBusinessInsertBy) {
    //   this.UserBusinessInsertBy.forEach((item) => {
    //     obj.UserBusinessInsertBy.push(item.removeRelated())
    //   })
    // }
    // obj.UserBusinessUpdateBy = []
    // if (this.UserBusinessUpdateBy) {
    //   this.UserBusinessUpdateBy.forEach((item) => {
    //     obj.UserBusinessUpdateBy.push(item.removeRelated())
    //   })
    // }
    obj.UserBusinessUser = []
    if (this.UserBusinessUser) {
      this.UserBusinessUser.forEach((item) => {
        obj.UserBusinessUser.push(item.removeRelated())
      })
    }
    obj.UserRole = []
    if (this.UserRole) {
      this.UserRole.forEach((item) => {
        obj.UserRole.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.UserBusinessUser = []
    if (this.UserBusinessUser) {
      this.UserBusinessUser.forEach((item) => {
        obj.UserBusinessUser.push(item.removeRelated())
      })
    }
    obj.UserRole = []
    if (this.UserRole) {
      this.UserRole.forEach((item) => {
        obj.UserRole.push(item.removeRelated())
      })
    }
  }
  root() {
    let obj = Object.assign({}, this)
    // obj.BusinessInsertBy = null
    // obj.BusinessUpdateBy = null
    // obj.Document = null
    // obj.HempLicenseAreaInsertBy = null
    // obj.HempLicenseAreaUpdateBy = null
    // obj.HempLicenseInsertBy = null
    // obj.HempLicenseUpdateBy = null
    // obj.LicenseDocumentTypesInsertBy = null
    // obj.LicenseDocumentTypesUpdateBy = null
    // obj.LicenseInsertBy = null
    // obj.LicenseNotesInsertBy = null
    // obj.LicenseNotesUpdateBy = null
    // obj.LicensePeriodInsertBy = null
    // obj.LicensePeriodUpdateBy = null
    // obj.LicenseStatus = null
    // obj.LicenseUpdateBy = null
    // obj.LuBusinessTypeInsertBy = null
    // obj.LuBusinessTypeUpdateBy = null
    // obj.LuDocumentTypeInsertBy = null
    // obj.LuDocumentTypeUpdateBy = null
    // obj.LuHempOwnershipInsertBy = null
    // obj.LuHempOwnershipUpdateBy = null
    // obj.LuLicenseStatusInsertBy = null
    // obj.LuLicenseStatusUpdateBy = null
    // obj.LuLicenseSubTypeInsertBy = null
    // obj.LuLicenseSubTypeUpdateBy = null
    // obj.LuLicenseTypeInsertBy = null
    // obj.LuLicenseTypeUpdateBy = null
    // obj.PaymentDetailInsertBy = null
    // obj.PaymentDetailUpdateBy = null
    // obj.PaymentInsertBy = null
    // obj.PaymentUpdateBy = null
    // obj.UserBusinessInsertBy = null
    // obj.UserBusinessUpdateBy = null
    obj.UserBusinessUser = null
    obj.UserRole = null
    return obj
  }
}
